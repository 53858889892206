import React from 'react';
import SkeletonCard from 'components/cards/skeleton';
import SkeletonFooter from 'components/footer/skeleton';
import { Skeleton } from '@material-ui/lab';

import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const SkeletonShareView = () => {
  const styles = useStyles();

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
        <div className={styles.menucontent}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            <Grid item md={4} sm={6} xs={12}>
              <SkeletonCard button qr/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SkeletonCard button qr/>
            </Grid>
          </Grid>
        </div>
        <SkeletonFooter/>
      </>
  );
};

export default SkeletonShareView;
